// 用来审核 博客信息的界面
<template>
<!--用来显示表格显示信息-->
    <div class="exam">
        <el-table :data="pagination.records" border>
            <el-table-column fixed="left" prop="title" label="标题" width="180"></el-table-column>
            <el-table-column prop="description" label="介绍" width="200"></el-table-column>
            <el-table-column prop="created" label="创建时间" width="120"></el-table-column>
            <el-table-column fixed="right" label="详细内容" width="250">
                <template slot-scope="scope">
                    <el-button type="info" size="small" @click="getContent(scope.row.content)">显示详情</el-button>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="250">
                <template slot-scope="scope">
                    <el-button type="info" size="small" @click="pass(scope.row)">同意</el-button>
                    <el-button type="info" size="small" @click="reject(scope.row)">拒绝</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pagination.current"
                :page-sizes="[4, 8, 10, 20]"
                :page-size="pagination.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pagination.total" class="page">
        </el-pagination>

        <!-- 显示全部信息的对话框-->
        <el-dialog
                title="提示"
                :visible.sync="dialogVisible"
                width="30%"
                :before-close="handleClose">
            <div v-html="content" class="markdown-body"></div>
        </el-dialog>
    </div>
</template>

<script>
    // 引入mk样式 ，对mk进行美化
    import "github-markdown-css/github-markdown.css" ;

    export default {
        name: "checkBlog",
        data(){
            return{
                // 准备表格显示信息
                pagination: { //分页后的考试信息
                    current: 1, //当前页
                    total: null, //记录条数
                    size: 4 //每页条数
                },
                dialogVisible:false , // 默认详细信息是不显示的
                content:'', // 用来 显 示 mk 的内容
            }
        },
        methods:{
            getInfo(){
                //准备向后端 传递过去分页信息
                let cur = this.pagination.current ;
                let size = this.pagination.size ;
                this.$axios({
                    url:'/checkBlogs',
                    method:'post',
                    params:{
                        cur:cur,
                        size:size
                    }
                }).then(res =>{
                    if(res.code == 200){
                        // 从后台获得相应的数据
                        this.pagination = res.data
                    }
                })
            },
            handleSizeChange(val){ // 改变 每一页的数量
                this.pagination.size = val ;
                this.getInfo()  ;
            },
            handleCurrentChange(val) {// 改变当前页
                this.pagination.current = val ;
                this.getInfo();
            },
            getContent(content){ // 将该mk 文件的内容显示出来
                this.dialogVisible = true ; // 将对话框显示出来
            //    准备 mk 工具
                var md = require('markdown-it')(),
                    mk = require('@iktakahiro/markdown-it-katex') ;
                md.use(mk) ;
                var render = md.render(content) ;
                this.content = render ;
            },
            handleClose(){ // 关闭对话框的操作
                this.$confirm('确认关闭？')
                    .then(_ => {
                        // 将 窗口 进行关闭
                        this.dialogVisible = false;
                    })
                    .catch(_ => {});
            },
            pass(info){ //通过操作
            //     通过 操作 操作  也进行 了简化
                let id = info.id  ;
                let _this = this ;

            //     向后台 传输 数据
                this.$axios({
                    url:'/passBolg',
                    method:'post',
                    params:{
                        id:id
                    }
                }).then(res =>{
                    if(res.code == 200){
                        _this.getInfo();
                        _this.$message({
                            type:'success',
                            message:'通过成功'
                        })
                    }

                }) ;
            },
            reject(info){ // 拒绝操作
                let id = info.id;
                let _this = this ;
                this.$confirm('是否删除？')
                    .then(_ => {
                        //    简化了 功能 ，实现 用根据 主键id 来进行删除
                        _this.$axios({
                            url:'/delBlog',
                            method:'post',
                            params:{
                                id:id
                            }
                        }).then(res=>{
                            if(res.code == 200){
                                _this.getInfo(); // 重新加载信息
                            //    显示 出来 拒绝成功的标识
                                _this.$message({
                                    type:'success',
                                    message:'拒绝成功'
                                });
                            }
                        }) ;
                    })
                    .catch(_ => {});
            }
        },
        created() {
            this.getInfo() ;
        }
    }
</script>

<style lang="scss" scoped>
    .exam {
        padding: 0px 40px;
        .page {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .edit{
            margin-left: 20px;
        }
    }
</style>